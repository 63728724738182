import useSWR, { Fetcher } from 'swr'

import cardService from '@/services/card.service'
import { CardType, GenericCardType } from '@/types/card'
import { formatUrl } from '@/utils/helpers'

type ArgType = { card: GenericCardType; name?: string }

const fetcher: Fetcher<CardType, ArgType> = async ({ card, name }: ArgType) => {
  if (name?.includes('|')) {
    const data = await cardService.getCard(formatUrl(name))
    data.typo = data?.name !== name.split('|')[0]
    return data
  } else if (card?.cards && card?.is_partner) {
    const card1 = await cardService.getCard(card?.cards[0]?.url as string)
    const card2 = await cardService.getCard(card?.cards[1]?.url as string)
    return {
      ...card1,
      ...card,
      cards: [card1, card2],
      image_uris: [card1.image_uris[0], card2.image_uris[0]],
    }
  } else {
    const data = await cardService.getCard(card?.sanitized_wo || card?.sanitized || formatUrl(card?.name || name || ''))
    data.typo = data?.name !== name
    return data
  }
}

export const useCard = ({ card, name }: ArgType) => {
  return useSWR({ card, name }, fetcher)
}
