import classNames from 'classnames'
import Link from 'next/link'
import { useState } from 'react'
import createBreakpoint from 'react-use/lib/factory/createBreakpoint'

import { ScryfallImageType } from '@/types/card'

import styles from './CardImage.module.scss'

const useBreakpoint = createBreakpoint({ sm: 576, xs: 0 })

type CardImageWrapperProps = {
  children: React.ReactNode
  onClick?: () => void
  url?: string | null
}

const CardImageWrapper = ({ children, onClick, url }: CardImageWrapperProps) =>
  onClick ? <div onClick={onClick}>{children}</div> : url ? <Link href={url}>{children}</Link> : children

type Props = {
  image_uris: string[] | ScryfallImageType[]
  isRotated: boolean
  onLoad: () => void
  name: string
  names: string[]
  children?: React.ReactNode
  url?: string
}

const CardImage = ({ children, image_uris, isRotated, name, onLoad, url }: Props) => {
  const breakpoint = useBreakpoint()
  const [selectedIndex, setSelectedIndex] = useState(0)

  /* On desktop, hovering the back card brings it to the front, and clicking either card opens the page
     On mobile, clicking the front card opens the page, and clicking the back card brings it to the front */
  if (image_uris.length == 2) {
    return (
      <div className={styles.container}>
        <CardImageWrapper url={breakpoint === 'sm' || selectedIndex === 0 ? url : undefined}>
          <img
            alt={name}
            className={classNames(styles.border, styles.front, 'shadow-sm', {
              [styles.rotate90]: isRotated,
              [styles.selected]: selectedIndex == 0,
            })}
            height={306}
            onClick={breakpoint === 'xs' && selectedIndex !== 0 ? () => setSelectedIndex(0) : undefined}
            onLoad={onLoad}
            src={typeof image_uris[0] === 'string' ? image_uris[0] : image_uris[0].normal}
            width={219.594}
          />
        </CardImageWrapper>
        <CardImageWrapper url={breakpoint === 'sm' || selectedIndex === 1 ? url : undefined}>
          <img
            alt={name}
            className={classNames(styles.border, styles.back, 'shadow-sm', {
              [styles.selected]: selectedIndex == 1,
            })}
            height={306}
            onClick={breakpoint === 'xs' && selectedIndex !== 1 ? () => setSelectedIndex(1) : undefined}
            onLoad={onLoad}
            src={typeof image_uris[1] === 'string' ? image_uris[1] : image_uris[1].normal}
            width={219.594}
          />
        </CardImageWrapper>
        {children}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <CardImageWrapper url={url}>
        <img
          alt={name}
          className={classNames(styles.border, 'shadow-sm', {
            [styles.rotate90]: isRotated,
          })}
          height={340}
          onLoad={onLoad}
          src={typeof image_uris[0] === 'string' ? image_uris[0] : image_uris[0].normal}
          width={244}
        />
      </CardImageWrapper>
      {children}
    </div>
  )
}

export default CardImage
