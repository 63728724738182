import classNames from 'classnames'
import { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'

import bannedImg from '@/assets/banned.png'
import unofficialImg from '@/assets/unofficial.png'
import toastService from '@/services/toast.service'
import { useAppSelector } from '@/store/hooks'
import { GenericCardType } from '@/types/card'
import { CardType, PageCardType } from '@/types/card'
import { useCard } from '@/utils/fetcher'
import { formatUrl } from '@/utils/helpers'
import usePatreonTier from '@/utils/usePatreonTier'

import styles from './Card.module.scss'
import CardButtons from './CardButtons/CardButtons'
import CardImage from './CardImage'
import CardLabel from './CardLabel/CardLabel'
import CardLoading from './CardLoading/CardLoading'
import CardPrices from './CardPrices/CardPrices'
import CardSalt from './CardSalt/CardSalt'

type Props = {
  background?: boolean
  card?: GenericCardType
  className?: string
  fullCard?: CardType
  label?: React.ReactNode
  name?: string
  new?: boolean
  pageCommander?: PageCardType
  routerUnavailable?: boolean
  isPreview?: boolean
}

function CardLazy(props: Props) {
  const { card, fullCard, name, pageCommander, routerUnavailable, isPreview } = props
  const alternateArt = useAppSelector((state) => state.user.alternateArt)
  const patreonTier = usePatreonTier()
  const [imageIsLoaded, setImageIsLoaded] = useState(false)
  const [isRotated, setIsRotated] = useState(false)

  useEffect(() => {
    setIsRotated(false)
  }, [card])

  // Fetch data
  // @ts-ignore
  const { data: fetchedData, error } = useCard({ card, name })
  const data =
    fetchedData ||
    (fullCard ? { ...fullCard, url: fullCard.sanitized || formatUrl(fullCard.name || name || '') } : undefined)

  if (error) return <>Failed to load {isPreview && <span className={styles.error}>{name}</span>}</>

  const { banned, new: isNew, prices, unofficial } = data || {}

  // image_uris
  let image_uris: string[] | undefined = undefined
  if (patreonTier === 'rare' && alternateArt && !name?.includes('|')) {
    if (card && card.cards && card.cards.length > 1 && card.is_partner) {
      image_uris = [0, 1].map((i) =>
        card.cards
          ? alternateArt[card.cards[i]?.url || '']
            ? alternateArt[card.cards[i]?.url || ''][0]
            : data
              ? data.image_uris[i]
              : ''
          : '',
      )
    } else if (data) {
      image_uris = alternateArt[data.url || data.sanitized || ''] || data.image_uris
    }
  } else if (data) {
    image_uris = data.image_uris
  }

  // If no url given, build a url from the name
  // If given url is null, pass in undefined (no link, Card is unclickable)
  // Otherwise, use the given url
  const url =
    card?.url !== undefined
      ? card.url === null
        ? undefined
        : card.url
      : `/${data?.legal_commander ? 'commanders' : 'cards'}/${formatUrl(name?.split('|')[0] || '')}`

  return (
    <>
      {banned ? (
        <div className={styles.banned}>
          <img alt='Banned' src={bannedImg.src} />
        </div>
      ) : unofficial ? (
        <div className={styles.banned}>
          <img alt='Unofficial' src={unofficialImg.src} />
        </div>
      ) : null}
      {data && (
        <>
          <CardButtons
            card={data}
            isRotated={isRotated}
            pageCommander={pageCommander}
            routerUnavailable={routerUnavailable}
            setIsRotated={setIsRotated}
          />
          {image_uris && (
            <CardImage
              image_uris={image_uris}
              isRotated={isRotated}
              name={data.name}
              names={data.names}
              onLoad={() => setImageIsLoaded(true)}
              url={url}
            >
              {props.new || isNew ? (
                <div className={styles.new}>
                  <span>NEW</span>
                </div>
              ) : (
                <CardSalt salt={data.salt} />
              )}
            </CardImage>
          )}
        </>
      )}
      {!imageIsLoaded && <CardLoading />}
      {prices && <CardPrices prices={prices} />}
    </>
  )
}

const Card = (props: Props) => {
  const namesUnderCards = useAppSelector((state) => state.user.namesUnderCards)

  const name = props.card?.name || props.name || props.fullCard?.name || ''

  return (
    <div className={classNames(styles.container, props.className)}>
      {!namesUnderCards && (
        <div
          className={styles.nameWrapper}
          onClick={() => {
            toastService.createToast(`Copied ${name}`)
            navigator.clipboard.writeText(name)
          }}
        >
          <span className={styles.name}>{name}</span>
        </div>
      )}
      <LazyLoad height={359.19} once>
        <CardLazy {...props} pageCommander={props.pageCommander} />
      </LazyLoad>
      {namesUnderCards && <div className={styles.nameUnderCard}>{name}</div>}
      <CardLabel
        pageCommander={props.pageCommander}
        label={props.label || props.card?.label}
        num_decks={props.card?.num_decks}
        potential_decks={props.card?.potential_decks}
        synergy={props.card?.synergy}
      />
    </div>
  )
}

export default Card
